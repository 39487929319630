import React, { useEffect, useState } from "react"
import queryString from "query-string"
import { Layout, SEO, CJumbotron, CBreadCrumb, MicroCmsLayout } from "../../../components/_index"

// markup
const SubPage = ({ location }: any) => {
  const { contentId, draftKey } = queryString.parse(location.search);
  const [data, setData] = useState<any | null>(null);
  useEffect(() => {
    fetch(`https://${process.env.MICRO_CMS_SERVICE_DOMAIN}.microcms.io/api/v1/info_yrph/${contentId}?draftKey=${draftKey}`, {
      headers: {
        'X-MICROCMS-API-KEY': process.env.MICRO_CMS_API_KEY || "",
      },
    })
      .then(res => res.json())
      .then(res => setData({ res }));
  }, []);

  if (data === null) {
    return null;
  }

  return (
    <Layout>
      <SEO title="プレビュー"
        description="プレビュー"
        ogtype={"blog"}
      />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'INFORMATION',
            sub: 'インフォメーション',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/information/kv.png',
              },
              imgSp: {
                src: '/assets/images/information/kv__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/information/kv.png',
              },
              imgSp: {
                src: '/assets/images/information/kv__sp.png',
              },
            },
          ],
        }}
      />
      <CBreadCrumb data={{
        parent: [
        ],
        current: {
          label: data.res.title
        }
      }} />
      <MicroCmsLayout data={data.res} />
    </Layout>
  )
}

export default SubPage;
